import cn from 'classnames'
import { get } from 'lodash'
import { useState } from 'react'
import css from './index.module.scss'

export const parseYoutubeVideoIdOrUrl = (youtubeVideoUrlOrId: string) => {
  if (!youtubeVideoUrlOrId.startsWith('http')) {
    youtubeVideoUrlOrId = `http://${youtubeVideoUrlOrId}?v=${youtubeVideoUrlOrId}`
  }
  const url = new URL(youtubeVideoUrlOrId)
  const id = url.searchParams.get('v')
  const widthString = url.searchParams.get('width')
  const heightString = url.searchParams.get('height')
  const width = widthString ? parseInt(widthString) : 560
  const height = heightString ? parseInt(heightString) : 364
  return { id, width, height }
}

export const getYoutubeVideoSize = ({ id, width, height }: { id: string; width?: number; height?: number }) => {
  const parsed = parseYoutubeVideoIdOrUrl(id)
  width = width || parsed.width
  height = height || parsed.height
  const ratio = height / width
  const ratioPercent = ratio * 100
  const ratioPercentString = `${ratioPercent.toFixed(2)}%`
  return {
    width,
    height,
    ratio,
    ratioPercent,
    ratioPercentString,
  }
}

export const getYoutubeVideoEmbedUrl = ({
  id,
  params,
}: {
  id: string
  params?: {
    modestbranding?: boolean
    rel?: boolean
    showinfo?: boolean
    controls?: boolean
  }
}) => {
  const modestbranding = get(params, 'modestbranding', true)
  const rel = get(params, 'rel', false)
  const showinfo = get(params, 'showinfo', false)
  const controls = get(params, 'controls', true)
  const parsed = parseYoutubeVideoIdOrUrl(id)
  // return `https://www.youtube.com/embed/${youtubeVideoId}?modestbranding=${modestbranding ? 1 : 0}&rel=${
  //   rel ? 1 : 0
  // }&showinfo=${showinfo ? 1 : 0}`
  const url = new URL(`https://www.youtube.com/embed/${parsed.id}`)
  url.searchParams.set('modestbranding', modestbranding ? '1' : '0')
  url.searchParams.set('rel', rel ? '1' : '0')
  url.searchParams.set('showinfo', showinfo ? '1' : '0')
  url.searchParams.set('controls', controls ? '1' : '0')
  return url.toString()
}

export const VideoYoutube = ({
  id,
  width,
  height,
  className,
  eventElementId,
  eventElementGroup,
}: {
  id: string
  width?: number
  height?: number
  className?: string
  eventElementId?: string
  eventElementGroup?: string
}) => {
  const embedUrl = getYoutubeVideoEmbedUrl({ id })
  const size = getYoutubeVideoSize({ id, width, height })
  const [title] = useState(Math.random().toString())
  return (
    <div className={cn(css.videoOuterOuter, className)}>
      <div
        className={css.videoOuter}
        style={{
          paddingBottom: size.ratioPercentString,
        }}
      >
        <iframe
          className={css.video}
          width={width}
          height={height}
          src={embedUrl}
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}
