import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { useEffect } from 'react'
import { EdCard } from '../../../../components/education/cards/EdCard'
import { Button } from '../../../../components/ui/Button'
import { Card, Cards } from '../../../../components/ui/Card'
import { type ColumnFillerItemType } from '../../../../components/ui/ColumnFiller'
import { useT } from '../../../../lib/i18n'
import { notify } from '../../../../lib/notify'
import { getGuideSourceCodeRoute } from '../../../../lib/routes'
import { trpc } from '../../../../lib/trpc'
import { HomePageSocial } from '../../../promo/general/HomePromoPage/HomePageSocial'
import { NextGuideLessonCard } from '../NextGuideLessonCard'
import css from './index.module.scss'

export const GuideLessonFooter = ({ lesson }: { lesson: TrpcRouterOutput['getStudentGuideLesson']['lesson'] }) => {
  const { t } = useT('guideLessonFooter')
  const queryResultGuide = trpc.getStudentGuideFull.useQuery(
    {
      guideSlug: lesson.guideSlug,
    },
    {
      staleTime: 3000,
    }
  )
  const guide = queryResultGuide.data?.guide
  const queryResultEdItems = trpc.getEdItems.useQuery(
    {
      ids: guide?.relatedEdItemsIds || [],
    },
    {
      enabled: !!guide?.relatedEdItemsIds.length,
    }
  )
  const edItems = !guide?.relatedEdItemsIds
    ? null
    : guide.relatedEdItemsIds.length
    ? queryResultEdItems.data?.edItems
    : []
  const notInvitedGithubRepos = guide?.githubRepos.filter((repo) => !repo.invitedAt) || []
  useEffect(() => {
    if (queryResultGuide.error) {
      notify({
        type: 'error',
        message: queryResultGuide.error.message,
      })
    }
    if (queryResultEdItems.error) {
      notify({
        type: 'error',
        message: queryResultEdItems.error.message,
      })
    }
  }, [queryResultGuide, queryResultEdItems])
  if (!guide || !edItems) {
    return null
  }
  return (
    <div className={css.guideLessonFooter}>
      <Cards
        items={[
          ...(lesson.nextLesson
            ? [
                {
                  key: 'nextLesson',
                  render: ({ setItemRef }) => <NextGuideLessonCard activeLesson={lesson} setRef={setItemRef} />,
                } as ColumnFillerItemType,
              ]
            : []),
          ...notInvitedGithubRepos.map(
            (repo) =>
              ({
                key: repo.id,
                render: ({ setItemRef }) => (
                  <Card
                    hintColor="gray"
                    hintIconName="code"
                    hintText={t('sourceCodeHint')}
                    title={repo.title}
                    content={repo.desc}
                    buttons={
                      <Button
                        color="brandAccentLight"
                        to={getGuideSourceCodeRoute({
                          guideSlug: guide.slug,
                        })}
                        eventElementId="guideLessonFooterSourceCodeButton"
                      >
                        {t('getSourceCodeButton')}
                      </Button>
                    }
                    setRef={setItemRef}
                  />
                ),
              } as ColumnFillerItemType)
          ),
          // {
          //   key: 'commuinty',
          //   render: ({ setItemRef }) => (
          //     <Card
          //       hintColor="blue-except-icon"
          //       hintIconName="community"
          //       hintText={t('communityHint')}
          //       title={t('communityTitle')}
          //       content={t('communityContent')}
          //       buttons={
          //         <Button
          //           color="brandAccentLight"
          //           href={getCommuinityRoute()}
          //           target="_blank"
          //           eventElementId="guideLessonFooterCommunityButton"
          //           fullWidth
          //         >
          //           {t('joinCommunityButton')}
          //         </Button>
          //       }
          //       setRef={setItemRef}
          //     />
          //   ),
          // },
          ...edItems.map(
            (edItem) =>
              ({
                key: edItem.id,
                render: ({ setItemRef }) => <EdCard edItem={edItem} setRef={setItemRef} />,
              } as ColumnFillerItemType)
          ),
        ]}
      />
      <HomePageSocial please noHorizontalPadding smallerTitle />
    </div>
  )
}
