import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { getYoutubeVideoEmbedUrl, getYoutubeVideoSize } from '../../../../components/other/VideoYoutube'
import css from './index.module.scss'

export const GuideLessonVideoYoutube = ({
  lesson,
}: {
  lesson: TrpcRouterOutput['getStudentGuideLesson']['lesson']
}) => {
  if (!lesson.youtubeVideoId) {
    return null
  }
  const embedUrl = getYoutubeVideoEmbedUrl({ id: lesson.youtubeVideoId })
  const { width, height, ratioPercentString } = getYoutubeVideoSize({ id: lesson.youtubeVideoId })
  return (
    <div className={css.videoOuterOuter}>
      <div
        className={css.videoOuter}
        style={{
          paddingBottom: ratioPercentString,
        }}
      >
        <iframe
          className={css.video}
          width={width}
          height={height}
          src={embedUrl}
          title={lesson.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}
