import type { TrpcRouterOutput } from '@svag/backend/src/router'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { atom } from 'nanostores'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Auth } from '../../../components/auth/Auth'
import { ConfirmEmailBlocker } from '../../../components/auth/ConfirmEmailBlocker'
import { FormItems } from '../../../components/form/FormItems'
import { OnRender } from '../../../components/other/OnRender'
import { Alert } from '../../../components/ui/Alert'
import { RichText } from '../../../components/ui/RichText'
import { Segment } from '../../../components/ui/Segment'
import { useT } from '../../../lib/i18n'
import {
  trackBlockedGuideLessonBecouseEmailNotConfirmed,
  trackBlockedGuideLessonBecouseLowPlan,
  trackBlockedGuideLessonBecouseNotAuthorized,
  trackViewGuideLessonOneMinute,
  trackVisitGuideLesson,
} from '../../../lib/mixpanel'
import { notify } from '../../../lib/notify'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { type GuideLessonRouteParams } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import { GuidePlans } from '../GuidePlans'
import { GuideLessonCommits } from './GuideLessonCommits'
import { GuideLessonFooter } from './GuideLessonFooter'
import { GuideLessonVideoKinescope } from './GuideLessonVideoKinescope'
import { GuideLessonVideoYoutube } from './GuideLessonVideoYoutube'
import css from './index.module.scss'

const pleaseMarkLessonAsCompletedTriggeredAtStore = atom<Date | null>(null)

const Lesson = ({
  lesson,
  guide,
  guideLessons,
}: {
  lesson: TrpcRouterOutput['getStudentGuideLesson']['lesson']
  guide: TrpcRouterOutput['getStudentGuideFull']['guide']
  guideLessons: TrpcRouterOutput['getStudentGuideFull']['lessons']
}) => {
  const { t } = useT('guideLessonPage')
  useEffect(() => {
    trackVisitGuideLesson({
      guideLesson: lesson,
    })
    const timeout = setTimeout(() => {
      trackViewGuideLessonOneMinute({
        guideLesson: lesson,
      })
    }, 60 * 1000)
    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson.slug])

  useEffect(() => {
    const pleaseMarkLessonAsCompletedTriggeredAt = pleaseMarkLessonAsCompletedTriggeredAtStore.get()
    const now = new Date()
    const diff = pleaseMarkLessonAsCompletedTriggeredAt
      ? Math.abs(differenceInMinutes(pleaseMarkLessonAsCompletedTriggeredAt, now))
      : Infinity
    if (diff < 10) {
      return
    }
    const thisLessonIndex = lesson.index
    if (thisLessonIndex === 0) {
      return
    }
    const thisLessonCompleted = lesson.isFinished
    if (thisLessonCompleted) {
      return
    }
    const guideCurrentLessonIndex = guide.currentGuideLesson?.index
    if (guideCurrentLessonIndex === undefined) {
      return
    }
    if (guideCurrentLessonIndex !== thisLessonIndex) {
      notify({
        type: 'neutral',
        message: t('notifications.notMarkedAsCompleted'),
        duration: 10000,
      })
      pleaseMarkLessonAsCompletedTriggeredAtStore.set(now)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson.slug, guide.slug])
  return (
    <Segment title={lesson.title}>
      <div className={css.lesson}>
        {lesson.kinescopeVideoId ? (
          <div className={css.videoPlace}>
            <GuideLessonVideoKinescope lesson={lesson} />
          </div>
        ) : lesson.youtubeVideoId ? (
          <div className={css.videoPlace}>
            <GuideLessonVideoYoutube lesson={lesson} />
          </div>
        ) : null}
        <div className={css.contentPlace}>
          <RichText html={lesson.contentHTML} />
        </div>
        {!!lesson.commits?.length && (
          <div className={css.commitsPlace}>
            <GuideLessonCommits guideSlug={lesson.guideSlug} commits={lesson.commits} />
          </div>
        )}
        <GuideLessonFooter lesson={lesson} />
      </div>
    </Segment>
  )
}

export const GuideLessonPage = withPageWrapper({
  title: ({ queriesResults }) => queriesResults[0].data?.lesson?.title,
  eventsContext: ({ queriesResults }) => ({
    guideLesson: queriesResults[0].data?.lesson,
    guide: queriesResults[1].data?.guide,
  }),
  useQueries: () => {
    const { guideSlug, lessonSlug } = useParams() as GuideLessonRouteParams
    return trpc.useQueries((t) => [
      t.getStudentGuideLesson({
        guideSlug,
        lessonSlug,
      }),
      t.getStudentGuideFull(
        {
          guideSlug,
        },
        {
          staleTime: 3000,
        }
      ),
    ])
  },
  showLoaderOnFetching: false,
  setProps: ({ queriesResults }) => ({
    lesson: queriesResults[0].data.lesson,
    guide: queriesResults[1].data.guide,
    guideLessons: queriesResults[1].data.lessons,
  }),
  langs: ['ru', 'en'],
})(({ lesson, guide, guideLessons }) => {
  const { t } = useT('guideLessonPage')

  if (lesson.isLockedBecouseNotAuthorized && lesson.isLockedBecouseLowPlan) {
    return (
      <Segment title={lesson.title}>
        <OnRender
          handler={() => {
            trackBlockedGuideLessonBecouseNotAuthorized()
          }}
          deps={[lesson.slug]}
        />
        <Auth
          segmentSize="M"
          policy="component"
          initialChoice="signUp"
          alertMessage={t('alerts.notAuthorizedAndLowPlan')}
        />
      </Segment>
    )
  }

  if (lesson.isLockedBecouseNotAuthorized) {
    return (
      <Segment title={lesson.title}>
        <OnRender
          handler={() => {
            trackBlockedGuideLessonBecouseNotAuthorized()
          }}
          deps={[lesson.slug]}
        />
        <Auth segmentSize="M" policy="component" initialChoice="signUp" alertMessage={t('alerts.notAuthorized')} />
      </Segment>
    )
  }

  if (lesson.isLockedBecouseEmailNotConfirmed && lesson.isLockedBecouseLowPlan) {
    return (
      <Segment title={lesson.title}>
        <OnRender
          handler={() => {
            trackBlockedGuideLessonBecouseEmailNotConfirmed()
          }}
          deps={[lesson.slug]}
        />
        <ConfirmEmailBlocker segmentSize="M" alertMessage={t('alerts.emailNotConfirmedAndLowPlan')} />
      </Segment>
    )
  }

  if (lesson.isLockedBecouseEmailNotConfirmed) {
    return (
      <Segment title={lesson.title}>
        <OnRender
          handler={() => {
            trackBlockedGuideLessonBecouseEmailNotConfirmed()
          }}
          deps={[lesson.slug]}
        />
        <ConfirmEmailBlocker segmentSize="M" alertMessage={t('alerts.emailNotConfirmed')} />
      </Segment>
    )
  }

  if (lesson.isLockedBecouseLowPlan) {
    return (
      <Segment title={lesson.title}>
        <OnRender
          handler={() => {
            trackBlockedGuideLessonBecouseLowPlan({
              currentPlan: guide.myPlan,
              minRequiredPlan: lesson.minRequiredPlan,
            })
          }}
          deps={[lesson.slug]}
        />
        <FormItems>
          <Alert color="brown">{t('alerts.lowPlan')}</Alert>
          <GuidePlans guideSlug={lesson.guideSlug} />
        </FormItems>
      </Segment>
    )
  }

  return <Lesson lesson={lesson} guide={guide} guideLessons={guideLessons} />
})
