import type { TrpcRouterOutput } from '@svag/backend/src/router'
import React, { useEffect } from 'react'
import { type EdItem } from '../../../../../components/education/cards/EdCard'
import { DashboardLayout } from '../../../../../components/layouts/DashboardLayout'
import {
  GeneralLayout,
  GeneralLayoutNavItem,
  GeneralLayoutNavLink,
  closeGeneralLayoutMenu,
} from '../../../../../components/layouts/GeneralLayout'
import { scrollToHash } from '../../../../../components/other/ScrollToHash'
import { Button } from '../../../../../components/ui/Button'
import { useT } from '../../../../../lib/i18n'
import { trackVisitEdPromo } from '../../../../../lib/mixpanel'
import { withPageWrapper } from '../../../../../lib/pageWrapper'
import { trpc } from '../../../../../lib/trpc'
import { HomePageSocial } from '../../../general/HomePromoPage/HomePageSocial'
import { EdDevPromoPageAbout } from './EdDevPromoPageAbout'
import { EdDevPromoPageArchitecture } from './EdDevPromoPageArchitecture'
import { EdDevPromoPageIntro } from './EdDevPromoPageIntro'
import { EdDevPromoPagePlans } from './EdDevPromoPagePlans'
import { EdDevPromoPageProgram } from './EdDevPromoPageProgram'
import { EdDevPromoPageTarget } from './EdDevPromoPageTarget'

const Layout = ({
  children,
  guide,
}: {
  children: React.ReactNode
  guide: TrpcRouterOutput['getStudentGuideFull']['guide']
}) => {
  const { t } = useT('edDevPromoPage')
  return (
    <GeneralLayout
      fullWidthContent
      // logoLinkPolicy="scrollToTop"
      logoLinkPolicy="navigateToHome"
      logoImagePolicy="education"
      showSidebar={false}
      parseWindowSize={(size) => ({
        isDesktop: size.width > 1250,
        isMobile: size.width <= 1250,
      })}
      footerBackgroundColor="brandAccent"
      generalNavigationChildren={
        <>
          <GeneralLayoutNavLink to="#target">{t('nav.forWhom')}</GeneralLayoutNavLink>
          <GeneralLayoutNavLink to="#about">{t('nav.about')}</GeneralLayoutNavLink>
          <GeneralLayoutNavLink to="#program">{t('nav.program')}</GeneralLayoutNavLink>
          <GeneralLayoutNavLink to="#architecture">{t('nav.architecture')}</GeneralLayoutNavLink>
          <GeneralLayoutNavItem>
            <Button
              color="brandAccent"
              onClick={() => {
                scrollToHash('#form')
                closeGeneralLayoutMenu()
              }}
              target="_blank"
              rel="noreferrer"
              eventElementGroup="edPromoStartButton"
              eventElementId="edPromoMenuStartButton"
            >
              {t('nav.startLearning')}
            </Button>
          </GeneralLayoutNavItem>
          {/* <GeneralLayoutAuthOrDashboardLink /> */}
        </>
      }
    >
      {children}
    </GeneralLayout>
  )
}

export const EdDevPromoPage = withPageWrapper({
  useQueries: () => {
    const queriesResults = trpc.useQueries((t) => [
      t.getStudentGuideFull({
        guideSlug: 'dev-web-iserdmi',
      }),
      t.getEdItems({
        ids: ['co-iserdmi-ed', 'group-dev-startup-iserdmi'],
      }),
    ])
    return queriesResults
  },
  title: ({ queriesResults }) => queriesResults[0].data?.guide?.title,
  eventsContext: ({ queriesResults }) => ({
    guide: queriesResults[0].data?.guide,
  }),
  ErrorLayout: DashboardLayout,
  setProps: ({ queriesResults }) => {
    const guide = queriesResults[0].data.guide
    const guideLessons = queriesResults[0].data.lessons
    const consultation = queriesResults[1].data.edItems.find((item) => item.id === 'co-iserdmi-ed') as Extract<
      EdItem,
      { type: 'consultation' }
    >
    const group = queriesResults[1].data.edItems.find((item) => item.id === 'group-dev-startup-iserdmi') as Extract<
      EdItem,
      { type: 'group' }
    >
    // if (!guide || !consultation || !group) {
    if (!guide || !consultation) {
      throw new Error('Oops, error')
    }
    return {
      guide,
      guideLessons,
      consultation,
      group,
    }
  },
  langs: ['ru', 'en'],
  showLoaderOnFetching: false,
})(({ guide, guideLessons, consultation, group }) => {
  useEffect(() => {
    trackVisitEdPromo({
      target: 'general',
    })
  }, [])
  return (
    <Layout guide={guide}>
      <EdDevPromoPageIntro guide={guide} />
      <EdDevPromoPageTarget />
      <EdDevPromoPageAbout guide={guide} consultation={consultation} group={group} />{' '}
      <EdDevPromoPageProgram guide={guide} guideLessons={guideLessons} />
      <EdDevPromoPageArchitecture />
      <HomePageSocial please />
      {/* <EdDevPromoPageYoutube guide={guide} /> */}
      <EdDevPromoPagePlans guide={guide} showTopMargin={true} />
    </Layout>
  )
})
